import { createContext } from 'react';

export const ConfigContext = createContext({ pages: [], theme: {}, workflows: [] });

// Higher Order Component (HOC) for ConfigContext to be used on class components
// class components using HOC can read context as props
export function ConfigContextHOC(Component) {
  return function contextComponent(props) {
    return (
      <ConfigContext.Consumer>
        {(configContext) => <Component {...props} configContext={configContext} />}
      </ConfigContext.Consumer>
    );
  };
}
