import React from 'react';
import { MenuItem, Button, Grid, Box, TextField } from '@mui/material';
import ModalWrapper from '../components/ModalWrapper';
import printer from '../assets/printAnimation.gif';
import { ConfigContextHOC } from '../context/ConfigContext';

class SetupForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      account_id: '',
      facility_of_origin_id: '',
      printer_ip: '',
      printer_port: '',
      printing: false,
      printing_error: '',
      workflow_idx: 0,
      workflow: {},
      identity: {},
      workflow_opts: [{ workflow_title: 'Workflow' }],
      isValid: true,
      initialized: false
    };
  }

  componentDidMount(): void {
    const { configContext } = this.props;
    this.setState({
      // NOTE: need to make this non-bt specific
      identity: configContext['identity'],
      account_id: '',
      facility_of_origin_id: '',
      workflow_opts: configContext['workflows'],
      initialized: true
    });
    // @ts-ignore
    window.startPrinting = this.startPrinting;
    // @ts-ignore
    window.stopPrinting = this.stopPrinting;
    // @ts-ignore
    window.stopPrintingWithError = this.stopPrintingWithError;
  }

  componentWillUnmount(): void {
    // @ts-ignore
    delete window.startPrinting;
    // @ts-ignore
    delete window.stopPrinting;
    // @ts-ignore
    delete window.stopPrintingWithError;
  }

  startPrinting = () => {
    this.setState(
      {
        printing: true
      },
      () => {
        // 60 second timer and then set error to "" so we go back to regular init
        setTimeout(() => {
          this.setState({
            printing: false
          });
        }, 60000);
      }
    );
  };

  stopPrinting = () => {
    this.setState({
      printing: false
    });
  };

  stopPrintingWithError = (errorMessage: string) => {
    this.setState(
      {
        printing_error: errorMessage
      },
      () => {
        // 45 second timer and then set error to "" so we go back to regular init
        setTimeout(() => {
          this.setState({
            printing: false,
            printing_error: ''
          });
        }, 45000);
      }
    );
  };

  HandleAccountChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    var account_id = event.target.value;
    this.setState({
      account_id: account_id
    });
  }

  HandleFacilityChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    var facility_id = event.target.value;
    this.setState({
      facility_of_origin_id: facility_id
    });
  }

  HandleWorkflowChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    var workflow_idx = event.target.value;
    var workflow = this.state.workflow_opts[workflow_idx];
    this.setState({
      workflow: workflow,
      workflow_idx: workflow_idx,
      isValid: true
    });
  }

  /*
    This is to let the android app know if we've entered
    the final form field so it can hide the keyboard if 
    enter is pressed
  */
  HandleKeyPress(event) {
    // @ts-ignore
    if (window.Android) {
      if (event.key === 'Enter') {
        // @ts-ignore
        window.Android.onLastFieldEntered();
      }
    }
  }

  ComposeResultsAndSumbit() {
    // @ts-ignore
    if (window.Android) {
      // url to compose: https://web-develop.freightroll.com/w/delivery/?acc=84&s=WORKFLOWS.DELIVERY.STEPS.DELIVERY
      var workflow = this.state.workflow_opts[this.state.workflow_idx];
      var account_id = this.state.account_id;
      var facility_id = this.state.facility_of_origin_id;

      var path =
        '/w/' +
        workflow['workflow_url_fragment'] +
        '?acc=' +
        account_id +
        '&fac=' +
        facility_id;
      var formatted_response = [account_id, workflow['workflow_title'], path];

      // @ts-ignore
      window.Android.sendConfiguration(formatted_response);
    } else {
      console.log('Android not found');
      var workflow = this.state.workflow_opts[this.state.workflow_idx];
      var account_id = this.state.account_id;
      var facility_id = this.state.facility_of_origin_id;
      var path =
        '/w/' +
        workflow['workflow_url_fragment'] +
        '?acc=' +
        account_id +
        '&fac=' +
        facility_id;
      var formatted_response = [account_id, workflow['workflow_title'], path];
      console.log(formatted_response);
    }
  }

  TestAndroidPrinting() {
    // @ts-ignore
    if (window.Android) {
      var print_obj = {
        method: 'ipp',
        value: `${window.location.protocol}//${window.location.host}/static/test_print_bol.pcl`
      };
      const test_print_page_ssp = [
        this.state.printer_ip,
        print_obj['method'],
        print_obj['value']
      ];
      var legacy_number_of_copies = 3;
      const test_print_page = [
        this.state.printer_ip,
        `${window.location.protocol}//${window.location.host}/static/test_bt_pdf_full.pdf`,
        legacy_number_of_copies.toString(),
        ''
      ];
      // @ts-ignore
      if (typeof window.Android.printDocumentInitSSP === 'function') {
        // @ts-ignore
        window.Android.printDocumentInitSSP(test_print_page_ssp);
      } else {
        // @ts-ignore
        window.Android.printDocumentInit(test_print_page);
      }
    }
  }

  AccountSelect() {
    // textbox for account id int
    return (
      <Box>
        <TextField
          id="account-id"
          label="Account ID"
          helperText="Please enter your account id"
          onChange={(e) => this.HandleAccountChange(e)}
          value={this.state.account_id}
        />
      </Box>
    );
  }

  FacilitySelect() {
    // textbox for facility id int
    return (
      <Box>
        <TextField
          id="facility-id"
          label="Facility ID"
          helperText="Please enter your facility id"
          onChange={(e) => this.HandleFacilityChange(e)}
          value={this.state.facility_of_origin_id}
        />
      </Box>
    );
  }

  WorkflowSelect() {
    return (
      <Box>
        <TextField
          id="workflow-select"
          select
          label="Workflow"
          helperText="Please select your workflow"
          onChange={(e) => this.HandleWorkflowChange(e)}
          value={this.state.workflow_idx}
        >
          {this.state.workflow_opts.map((workflow: any, index: number) => (
            <MenuItem key={workflow['workflow_url_fragment']} value={index}>
              {`${workflow['workflow_title']} (${workflow['workflow_url_fragment']})`}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    );
  }

  HandlePrinterIPChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const printer_ip = event.target.value;
    this.setState({
      printer_ip: printer_ip
    });
  }

  HandlePrinterPortChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const printer_port = event.target.value;
    this.setState({
      printer_port: printer_port
    });
  }

  PrinterForm() {
    return (
      <div className="App">
        <h2>Printer Configuration</h2>
        <Box>
          <TextField
            id="printer-ip"
            label="Printer IPv4 Address"
            helperText="Please enter your printer's IPv4 address"
            onChange={(e) => this.HandlePrinterIPChange(e)}
            value={this.state.printer_ip}
            onKeyDown={(e) => this.HandleKeyPress(e)}
          />
        </Box>
        <Box mt={2}>
          <Button
            key="printing"
            variant="outlined"
            disabled={this.state.printer_ip === ''}
            onClick={() => this.TestAndroidPrinting()}
          >
            Click to Test Printing
          </Button>
        </Box>
      </div>
    );
  }

  Form() {
    return (
      <div className="App">
        <h1>Please Configure Kiosk</h1>
        <Grid>{this.AccountSelect()}</Grid>
        <Grid>{this.FacilitySelect()}</Grid>
        <Grid>{this.WorkflowSelect()}</Grid>
        <Button
          key="submit"
          variant="outlined"
          onClick={() => this.ComposeResultsAndSumbit()}
        >
          Submit
        </Button>
      </div>
    );
  }

  render() {
    const { printing, printing_error, initialized } = this.state;

    if (initialized) {
      return (
        <>
          <ModalWrapper open={!!printing || !!printing_error}>
            {!!printing_error ? (
              <div>
                <p>Printing Job Failed</p>
                <p>{printing_error}</p>
              </div>
            ) : (
              <div>
                <p>Printing...</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={printer} alt="printer" width="300" />
                </div>
              </div>
            )}
          </ModalWrapper>

          {this.Form()}
          {this.PrinterForm()}
        </>
      );
    } else {
      return (
        <div>
          <p>Loading configuration file...</p>
        </div>
      );
    }
  }
}

export default ConfigContextHOC(SetupForm);
