import React, { useState, useEffect } from 'react';
import config from '../assets/config.json';
// making a custom react hook like this

export default function Config() {
  // get account id from query string parameter acc
  let search = window.location.search;
  let searchParams = new URLSearchParams(search);
  let account_id = searchParams.get('acc') || '';
  const { configs } = config;
  let identityMatch = configs.find((config: any) => {
    return config.identity.account_id === account_id;
  });
  if (!identityMatch) {
    identityMatch = configs.find((config: any) => {
      return config.identity.account_id === '*';
    });
  }
  return identityMatch || { pages: [], theme: {}, workflows: [] };
}
