import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

const MobileCheckInSuccess = ({ directLink }: { directLink?: boolean }) => {
  const [frId, setFrId] = useState('');
  const [loadNumber, setLoadNumber] = useState('');
  const [fromLink, setFromLink] = useState(directLink);
  const [loading, setLoading] = useState(false);

  const { b64LinkCode } = useParams<{ b64LinkCode?: string }>();
  const [deviceID, setDeviceID] = useState('');

  useEffect(() => {
    // get deviceID from session storage otherwise generate an uuid for it and save it to session storage
    let device_id = localStorage.getItem('deviceID');
    if (!device_id) {
      device_id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      localStorage.setItem('deviceID', device_id);
    }
    setDeviceID(device_id);
  }, []);

  useEffect(() => {
    const fetchLinkObj = async () => {
      if (b64LinkCode) {
        setLoading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/link/${b64LinkCode}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Device-Id': deviceID
              }
            }
          );
          const data = await response.json();
          if (data.fr_id !== 'temp') {
            setFrId(data.fr_id); // Assuming the response includes fr_id
          }
          setLoadNumber(data.load_number); // Assuming the response includes load_number
          setFromLink(true);
        } catch (error) {
          console.error('Failed to fetch link object:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLinkObj();
  }, [b64LinkCode]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '16px'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <h1>Thank You!</h1>
          </div>
          <p>Check In Complete for Shipment #: {loadNumber}</p>
          {!!frId && <p>Your FreightRoll ID is: {frId}</p>}
          <p style={{ textTransform: 'uppercase' }}>Lives: Await dock assignment text.</p>
          <p style={{ textTransform: 'uppercase' }}>
            Drops: Speak to DC about next steps.
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default MobileCheckInSuccess;
