import './App.css';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIdleTimer } from 'react-idle-timer';
import { AppBar, Button, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import Page from './common/Page';
import Config from './common/Config';
import Workflow from './common/Workflow';
import Bol from './common/Bol';
import { AppType } from './types/App';
import { DriverContext } from './context/DriverContext';
import ModalWrapper from './components/ModalWrapper';
import IdleModalContent from './components/IdleModalContent';
import { useParams } from 'react-router-dom';

// Type 'never[]' is not assignable to type '[]'.
// Type 'never' is not assignable to type 'never[]'.
interface AppProps {
  type?: AppType;
}

const App = ({ type }: AppProps) => {
  const [driver, setDriver] = useState({ name: 'Garrett', phone: '1234123412' });
  const [idleModalOpen, setIdleModalOpen] = useState(false);
  const removeCookie = useCookies(['googtrans'])[2];

  const [config, setConfig] = useState({ pages: [], theme: {}, workflows: [] });
  const [deviceID, setDeviceID] = useState('');
  const setHelpRef = useState<any>(null)[1];
  const [scrollY, setScrollY] = useState(0);
  const [barButtonLabel, setBarButtonLabel] = useState('');
  const [barButtonClick, setBarButtonClick] = useState<any>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [viewport, setViewport] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight
  });

  const [loading, setLoading] = useState(false);
  const [linkObj, setLinkObj] = useState(null);
  const { b64LinkCode } = useParams();

  const searchParams = new URLSearchParams(window.location.search);
  const acc = searchParams.get('acc');
  const fac = searchParams.get('fac');
  const idleTimer = searchParams.get('idleTimer');
  const idleModalTimer = searchParams.get('idleModalTimer');

  useEffect(() => {
    // get deviceID from session storage otherwise generate an uuid for it and save it to session storage
    let device_id = localStorage.getItem('deviceID');
    if (!device_id) {
      device_id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      localStorage.setItem('deviceID', device_id);
    }
    setDeviceID(device_id);
  }, []);

  let content;
  useEffect(() => {
    // Config().then((data) => {
    //   setConfig(data);
    // });
    const configData = Config();
    setConfig(configData);
  }, []);

  useEffect(() => {
    // console.log('state after update:', config);
  }, [config]);

  useEffect(() => {
    if (type === 'LINK' && b64LinkCode && !linkObj) {
      const fetchLinkObj = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/link/${b64LinkCode}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Device-Id': deviceID
              }
            }
          );
          // const response = await fetch(`${process.env.REACT_APP_base}/api/v1/link/${b64LinkCode}`);
          const data = await response.json();
          setLinkObj(data);
        } catch (error) {
          console.error('Failed to fetch link object:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchLinkObj();
    }
  }, [type, b64LinkCode]);

  const setScrollPosition = (scrollY: number) => {
    window.scrollTo(0, scrollY);
  };

  useLayoutEffect(() => {
    // wait 2 seconds before setting the scroll position
    if (isScrolledToBottom) {
      setTimeout(() => {
        setScrollPosition(scrollY);
      }, 50);
    }
  }, [isScrolledToBottom]);

  useEffect(() => {
    const onScroll = () => {
      const bottom =
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 200;
      if (bottom) {
        // after 2 seconds set it
        setScrollY(window.scrollY);
        setIsScrolledToBottom(true);
      }
    };
    if (type === 'SCROLL_STATIC') {
      window.addEventListener('scroll', onScroll);
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setAuth(event.target.checked);
  // };

  const onPrompt = () => {
    setIdleModalOpen(true);
  };

  const handleStillHere = () => {
    start();
    setIdleModalOpen(false);
  };

  const handleExit = () => {
    // @ts-ignore
    if (window.Android) {
      // delete all local and session storage
      sessionStorage.clear();
      localStorage.clear();
      // delete cookies
      removeCookie('googtrans', { path: '/' });
      document.cookie.split(';').forEach((c) => {
        document.cookie = c;
      });
      // reload page
      window.location.reload();
    }
  };

  // //unload
  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     // @ts-ignore
  //     if (!!window.Android) {
  //       e.preventDefault();
  //       e.returnValue = '';
  //     }

  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [])

  let timeout = 30000 * 3; // 60 seconds plus 30 seconds for modal
  let promptBeforeIdle = 30000; // 30 second countdown (modal opens 30 seconds before timeout)
  // allow idle timer overrides on local and develop
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'web-develop.freightroll.com'
  ) {
    if (idleModalTimer) {
      promptBeforeIdle = parseInt(idleModalTimer) * 1000;
    }
    if (idleTimer) {
      timeout = parseInt(idleTimer) * 1000 + promptBeforeIdle;
    }
  }

  const { start, getRemainingTime, isPrompted } = useIdleTimer({
    startManually: true,
    onIdle: () => handleExit(),
    onPrompt,
    timeout, // Amount of time before modal closes
    promptBeforeIdle, // Amount of time before timeout that modal opens
    throttle: 500,
    stopOnIdle: true
  });

  useEffect(() => {
    const handleActivity = () => {
      // Don't restart timer if user has already been prompted
      if (!isPrompted()) {
        start();
      }
    };

    // Attach event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('touchstart', handleActivity);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('touchstart', handleActivity);
    };
  }, [start]);

  // handle help button click opening a new page /p/help with current acc query string

  //NOTE: this will still work in the current iteration for encrypted links (acc and fac will be null), but something we'll want to eventually fix
  // @ts-ignore
  const helpButton = !window.Android ? (
    <IconButton
      size="small"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={() => {
        setHelpRef(window.open('/p/help?acc=' + acc + '&fac=' + fac, '_blank'));
      }}
      color="inherit"
    >
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Help
      </Typography>
    </IconButton>
  ) : (
    <></>
  );

  const handleHelpClose = () => {
    window.parent.window.close();
  };
  const closeBtn = (
    <IconButton
      size="small"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleHelpClose}
      color="inherit"
    >
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Close
      </Typography>
    </IconButton>
  );

  const showHelpOrClose = () => {
    if (type === 'STATIC') {
      return closeBtn;
    } else {
      return helpButton;
    }
  };

  let printerIpString = 'No Printer IP Address Found';
  // @ts-ignore
  let shouldShowPrinterIp = window.Android && window.Android.getPrinterIp();
  if (shouldShowPrinterIp) {
    // @ts-ignore
    printerIpString = `Printer IP Address: ${window.Android.getPrinterIp()}`;
  }

  // @ts-ignore
  let shouldShowRestart = !window.Android;

  const header = (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Grid container spacing={1} paddingTop={2}>
          <Grid item>{showHelpOrClose()}</Grid>
          {/*{backButton}*/}
          <Grid item justifyContent={'center'}>
            <div id="google_translate_element"></div>
          </Grid>
          {shouldShowPrinterIp && (
            <Grid item>
              <p style={{ marginTop: '12px' }}>{printerIpString}</p>
            </Grid>
          )}
          <Grid item flex={1}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingBottom: '10px'
              }}
            >
              {shouldShowRestart && (
                <Button
                  style={{
                    marginRight: '10px',
                    color: 'white',
                    backgroundColor: 'red'
                  }}
                  onClick={() => {
                    //@ts-ignore
                    if (window.Android) {
                      // delete all cookies, session storage and local storage
                      document.cookie.split(';').forEach((c) => {
                        document.cookie = c
                          .replace(/^ +/, '')
                          .replace(
                            /=.*/,
                            '=;expires=' + new Date().toUTCString() + ';path=/'
                          );
                      });
                      localStorage.clear();
                      sessionStorage.clear();
                      window.location.reload();
                    } else {
                      // for mobile check in, clear query params and reload page
                      window.location.assign(window.location.href.split('?')[0]);
                    }
                  }}
                >
                  Restart
                </Button>
              )}
              <Button
                style={{
                  float: 'right',
                  color: 'white',
                  backgroundColor: 'red'
                }}
                onClick={() => {
                  // delete all cookies, session storage and local storage
                  document.cookie.split(';').forEach((c) => {
                    document.cookie = c
                      .replace(/^ +/, '')
                      .replace(
                        /=.*/,
                        '=;expires=' + new Date().toUTCString() + ';path=/'
                      );
                  });
                  localStorage.clear();
                  sessionStorage.clear();

                  //@ts-ignore
                  if (window.Android) {
                    window.location.reload();
                  } else {
                    // for mobile check in, clear query params and reload page
                    window.location.assign(window.location.href.split('?')[0]);
                  }
                }}
              >
                Logout
              </Button>
            </div>
          </Grid>
        </Grid>
        {/*<Button>Back</Button>*/}
      </Toolbar>
    </AppBar>
  );

  let barButton: any;

  switch (type) {
    case 'STATIC':
      content = (
        <div className="App">
          <Page
            pages={config.pages}
            theme={config.theme}
            setBarButton={(label: string, callback: any) => {
              setBarButtonLabel(label);

              setBarButtonClick(() => callback);
            }}
          />
        </div>
      );
      break;
    case 'SCROLL_STATIC':
      content = (
        <div className="App">
          <Page
            pages={config.pages}
            theme={config.theme}
            setBarButton={(label: string, callback: any) => {
              if (label !== barButtonLabel && callback !== barButtonClick) {
                setBarButtonLabel(label);

                setBarButtonClick(() => callback);
              }
            }}
          />
        </div>
      );
      break;
    case 'BOL':
      content = (
        <div className="App">
          <Bol
            base_url_api={process.env.REACT_APP_API_BASE_URL}
            base_url_hub={process.env.REACT_APP_HUB_BASE_URL}
            device_id={deviceID}
            theme={config.theme}
            setBarButton={(label: string, callback: any) => {}}
          />
        </div>
      );
      break;

    case 'WORKFLOW':
      content = (
        <div className="App">
          <Workflow
            base_url_api={process.env.REACT_APP_API_BASE_URL}
            base_url_hub={process.env.REACT_APP_HUB_BASE_URL}
            device_id={deviceID}
            workflows={config.workflows}
            theme={config.theme}
            setBarButton={(label: string, callback: any) => {
              setBarButtonLabel(label);

              setBarButtonClick(() => callback);
            }}
            link_obj={null}
            account_id={null}
            facility_id={null}
          />
        </div>
      );
      break;
    case 'LINK':
      if (loading) {
        content = <div>Loading...</div>;
      } else if (
        linkObj &&
        'workflow_name' in linkObj &&
        'account_id' in linkObj &&
        'facility_id' in linkObj
      ) {
        content = (
          <div className="App">
            <Workflow
              base_url_api={process.env.REACT_APP_API_BASE_URL}
              base_url_hub={process.env.REACT_APP_HUB_BASE_URL}
              device_id={deviceID}
              workflows={config.workflows}
              theme={config.theme}
              setBarButton={(label: string, callback: any) => {
                setBarButtonLabel(label);

                setBarButtonClick(() => callback);
              }}
              link_obj={linkObj}
              account_id={linkObj['account_id']}
              facility_id={linkObj['facility_id']}
            />
          </div>
        );
      } else {
        content = (
          <div className="App">
            <Page
              pages={config.pages}
              theme={config.theme}
              setBarButton={(label: string, callback: any) => {
                setBarButtonLabel(label);

                setBarButtonClick(() => callback);
              }}
            />
          </div>
        );
      }
      break;
    default:
      content = (
        <div className="App">
          <Page
            pages={config.pages}
            theme={config.theme}
            setBarButton={(label: string, callback: any) => {
              setBarButtonLabel(label);

              setBarButtonClick(() => callback);
            }}
          />
        </div>
      );
      break;
  }

  if (barButtonLabel !== '') {
    barButton = (
      <Button variant="contained" color="warning" onClick={barButtonClick}>
        {barButtonLabel}
      </Button>
    );
  }
  const footer = (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', padding: 0, bottom: 0 }}>
      <Toolbar
        style={{
          display: 'flex',
          padding: 0,
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Grid
          justifyContent={'center'}
          alignItems={'center'}
          spacing={2}
          container={true}
        >
          <Grid item xs={3}>
            {barButton}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );

  return (
    <DriverContext.Provider value={driver}>
      <Grid style={{}}>
        {/* @ts-ignore */}
        <ModalWrapper open={idleModalOpen && window.Android}>
          <IdleModalContent
            onStillHere={handleStillHere}
            onExit={handleExit}
            getRemainingTime={getRemainingTime}
          />
        </ModalWrapper>
        <Grid>{header}</Grid>
        <Grid
          style={{
            paddingBottom: '10%',
            minHeight: '100%'
          }}
        >
          {content}
        </Grid>
        {!!barButton && <Grid>{footer}</Grid>}
      </Grid>
    </DriverContext.Provider>
  );
};

export default App;
